import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";

const routeRoot = 'deviceTypeCommands/';

export interface IDeviceTypeCommand {
  id: number;
  idDeviceType: number;
  idDeviceTypeDetails: number;
  commandName: string;
  commandNameEng: string;
  commandValue: string;
  enableOnValue: number;
  sortOrder: number;
  isGeneral: boolean;
  minutesDelay: number;
}

const deviceTypeCommandInfoApi = createApi({
  tagTypes: ['DeviceTypeCommand','Dashboard'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'deviceTypeCommandsReducer',
  endpoints: (build) => ({
    getAllDeviceTypesCommand: build.query<IDeviceTypeCommand[], void>({
      query: () => routeRoot,
      keepUnusedDataFor: 120,
      providesTags: ['DeviceTypeCommand','Dashboard']
    }),
    getAllDeviceTypesCommandByIdDeviceType: build.query<IDeviceTypeCommand[], number>({
      query: (idDeviceType) => routeRoot.concat(`/${idDeviceType}`),
      keepUnusedDataFor: 120,
      providesTags: ['DeviceTypeCommand','Dashboard']
    }),
    getAllDeviceTypesCommandToShowByIdDeviceType: build.query<IDeviceTypeCommand[], number>({
      query: (idDeviceType) => routeRoot.concat(`/Show/${idDeviceType}`),
      keepUnusedDataFor: 120,
      providesTags: ['DeviceTypeCommand','Dashboard']
    }),
    getDeviceTypesCommandById: build.query<IDeviceTypeCommand, number>({
      query: (id) => routeRoot.concat(`/id/${id}`),
      keepUnusedDataFor: 120,
      providesTags: ['DeviceTypeCommand','Dashboard']
    }),
    addDeviceTypeCommand: build.mutation<any, { body: Partial<IDeviceTypeCommand> }>({
      query: ({ body }) => ({
        url: routeRoot,
        method: 'POST',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['DeviceTypeCommand','Dashboard'],
    }),
    updateDeviceTypeCommand: build.mutation<any, { body: Partial<IDeviceTypeCommand>  }>({
      query: ({ body }) => ({
        url: routeRoot.concat(body.id?.toString() || ''),
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['DeviceTypeCommand','Dashboard'],
    }),
    deleteDeviceTypeCommand: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: routeRoot.concat(id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: ['DeviceTypeCommand','Dashboard'],
    })
  }),
});

export default deviceTypeCommandInfoApi;

export const {
  useGetAllDeviceTypesCommandQuery,
  useGetAllDeviceTypesCommandByIdDeviceTypeQuery,
  useGetAllDeviceTypesCommandToShowByIdDeviceTypeQuery,
  useAddDeviceTypeCommandMutation,
  useUpdateDeviceTypeCommandMutation,
  useDeleteDeviceTypeCommandMutation,
} = deviceTypeCommandInfoApi;
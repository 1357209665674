import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";

const routeRoot = 'storeDevices/';

export interface IStoreDevice {
  id: number;
  idStore: number;
  idDevice: number;
}


const storeDevicesApi = createApi({
  tagTypes: ['StoreDevices', 'Dashboard'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'StoreDeviceReducer',
  endpoints: (build) => ({
    getAllStoreDevices: build.query<IStoreDevice[], void>({
      query: () => routeRoot,
      keepUnusedDataFor: 120,
      providesTags: ['StoreDevices']
    }),
    getStoreDevicesByStoreId: build.query<IStoreDevice[], number>({
      query: (idStore) => routeRoot.concat(`/${idStore}`),
      keepUnusedDataFor: 120,
      providesTags: ['StoreDevices']
    }),
    addStoreDevice: build.mutation<any, { body: Partial<IStoreDevice> }>({
      query: ({ body }) => ({
        url: routeRoot,
        method: 'POST',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['StoreDevices','Dashboard'],
    }),
    deleteStoreDevice: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: routeRoot.concat(id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: ['StoreDevices','Dashboard'],
    })
  }),
});

export default storeDevicesApi;

export const {
  useGetAllStoreDevicesQuery,
  useGetStoreDevicesByStoreIdQuery,
  useAddStoreDeviceMutation,
  useDeleteStoreDeviceMutation
} = storeDevicesApi;
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../store/rootReducer";
import { LanguageInfo, setLanguage } from "../store/slices/language";
import { Langs } from "../translations/constants";

const useLanguage = () => {
  const { language } = useSelector<RootState, LanguageInfo>((state) => state.language);
  const dispatch = useDispatch();

  return {
    language,
    update: (language: Langs) => dispatch(setLanguage({ language }))
  }
};

export default useLanguage;
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";

const routeRoot = 'userStores/';

export interface IUserStore {
  id: number;
  idUser: number;
  idStore: number;
}


const userStoreInfoApi = createApi({
  tagTypes: ['UserStores', 'Dashboard'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'userStoreReducer',
  endpoints: (build) => ({
    getAllUserStores: build.query<IUserStore[], void>({
      query: () => routeRoot,
      keepUnusedDataFor: 120,
      providesTags: ['UserStores']
    }),
    getUserStores: build.query<IUserStore[], string>({
      query: (sessionId) => routeRoot.concat('me/'),
      keepUnusedDataFor: 120,
      providesTags: ['UserStores']
    }),
    addUserStore: build.mutation<any, { body: Partial<IUserStore> }>({
      query: ({ body }) => ({
        url: routeRoot,
        method: 'POST',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['UserStores','Dashboard'],
    }),
    deleteUserStore: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: routeRoot.concat(id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: ['UserStores','Dashboard'],
    })
  }),
});

export default userStoreInfoApi;

export const {
  useGetAllUserStoresQuery,
  useGetUserStoresQuery,
  useAddUserStoreMutation,
  useDeleteUserStoreMutation,
} = userStoreInfoApi;
import { Navigate } from "react-router-dom";

import useUserInfo from "../hooks/useUserInfo";

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute = ({ children }: PrivateRouteProps): JSX.Element | null => {
  const { userInfo: { token, expiresIn } } = useUserInfo();
  return token && expiresIn > Date.now() ? children : <Navigate to="/login" />;
};



export default PrivateRoute;


import { FC } from "react";

import {
  Box,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TextField,
} from "@material-ui/core";

interface CustomInputProps {
  type?: 'text' | 'checkbox' | 'select' | string;
  options?: Array<string>;
  inputType?: string;
  defaultValue: string | boolean;
  value: string | boolean;
  label: string;
  onChange: (value: string | boolean) => void;
}

const CustomInputs: FC<CustomInputProps> = (props) => {

  const boxProps = {
    pb: 2,
    width: '100%',
  };

  if(props.type === 'checkbox') {
    return (<Box {...boxProps}>
      <FormControlLabel
        value="top"
        control={<Checkbox
          checked={(props.value || props.defaultValue) as boolean}
          onChange={(ev) => props.onChange(ev.target.checked)}
        />}
        label={props.label}
        labelPlacement="end"
      />
    </Box>)
  }

  if(props.type === 'select') {
    return (<Box {...boxProps}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label"> {props.label} </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          variant="filled"
          label={props.label}
          value={props.value || props.defaultValue}
          onChange={(ev) => {
            props.onChange(ev.target.value as string);
          }}
        >
          {props.options?.map((val) => <MenuItem key={val} value={val}> {val} </MenuItem>)}
        </Select>
      </FormControl>
    </Box>);
  };

  if(props.type === 'text') {
    return (<Box {...boxProps}>
      <FormControl fullWidth>
        <TextField
          onChange={(ev) => {
            props.onChange(ev.target.value);
          }}
          label={props.label}
          variant="filled"
          type={props.inputType || 'text'}
          autoComplete="off"
          name="none"
          value={props.value || props.defaultValue}
        />
      </FormControl>
    </Box>);
  }

  return null;
};

export default CustomInputs;
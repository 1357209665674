import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";

const routeRoot = 'deviceTypes/';

export interface IDeviceType {
  id: number;
  name: string;
  notes: string;
}

const deviceTypeInfoApi = createApi({
  tagTypes: ['DeviceType','Dashboard'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'deviceTypeReducer',
  endpoints: (build) => ({
    getAllDeviceTypes: build.query<IDeviceType[], void>({
      query: () => routeRoot,
      keepUnusedDataFor: 120,
      providesTags: ['DeviceType','Dashboard']
    }),
    addDeviceType: build.mutation<any, { body: Partial<IDeviceType> }>({
      query: ({ body }) => ({
        url: routeRoot,
        method: 'POST',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['DeviceType','Dashboard'],
    }),
    updateDeviceType: build.mutation<any, { body: Partial<IDeviceType>  }>({
      query: ({ body }) => ({
        url: routeRoot.concat(body.id?.toString() || ''),
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['DeviceType','Dashboard'],
    }),
    deleteDeviceType: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: routeRoot.concat(id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: ['DeviceType','Dashboard'],
    })
  }),
});

export default deviceTypeInfoApi;

export const {
  useGetAllDeviceTypesQuery,
  useAddDeviceTypeMutation,
  useUpdateDeviceTypeMutation,
  useDeleteDeviceTypeMutation,
} = deviceTypeInfoApi;
import { List, ListItem, Box, styled, alpha, Typography } from "@material-ui/core";
import { NavLink as Link } from "react-router-dom";
import {
  Home,
  PersonOutline,
  SettingsApplications,
  Settings,
  Person,
  DeveloperBoard,
  House
} from "@material-ui/icons";

import { useGetUserInfoQuery } from "api/userApi";
import useFormattedMessage from "translations/useFormattedMessage";

const MyLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  width: '100%',
  borderRadius: '6px',
  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  margin: `0px ${theme.spacing(1)}px`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  "& span": {
    marginLeft: theme.spacing(2),
  },
  "&:hover": {
    background: alpha(theme.palette.primary.main, .6),
    color: "#FFF"
  },
  "&.active": {
    background: alpha(theme.palette.primary.main, .2),
    color: theme.palette.primary.main,
  },
}));

const computepath = (user: string) => {
  if(user === 'ADMIN' || user === 'SUPERADMIN' ) {
    return [
      {
        text: 'home',
        to: '/',
        Icon: Home,
      },
      {
        text: 'profile',
        to: '/profile',
        Icon: PersonOutline,
      },
      {
        text: 'settings',
        to: '/settings',
        Icon: SettingsApplications,
      },
      {
        text: 'management',
        to: '/management',
        Icon: Settings,
        subs: [
          {
            text: 'users',
            to: 'users',
            Icon: Person,
          },
          {
            text: 'stores',
            to: 'stores',
            Icon: House,
          },
          {
            text: 'devices',
            to: 'devices',
            Icon: DeveloperBoard,
          },
          {
            text: 'deviceTypes',
            to: 'deviceTypes',
            Icon: DeveloperBoard,
          }
        ]
      }
    ];
  }
  return [
    {
      text: 'home',
      to: '/',
      Icon: Home,
    },
    {
      text: 'profile',
      to: '/profile',
      Icon: PersonOutline,
    },
    {
      text: 'settings',
      to: '/settings',
      Icon: SettingsApplications,
    }
  ]
}

export const Navigation = ({ onClick }: any) => {
  const t = useFormattedMessage({ prefix: 'nav.' });
  const { data } = useGetUserInfoQuery();
  console.log(data);
  const  { role = '' } = data || {};
  console.log(role);
  const paths = computepath(role);
  
  return (
    <Box pt={12}>
      <List onClick={onClick}>
        {
          paths.map(({ text, to, Icon, subs }) => {

            if(subs && subs.length > 0) {
              return (
                <ListItem
                  disableGutters
                  key={`${text}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginTop: 16,
                  }}
                >
                  <Typography
                    variant="button"
                    color="primary"
                    style={{
                      marginLeft: 12,
                    }}
                  >
                    {t(text)}
                  </Typography  >
                  <List>
                    {subs.map((sub) => {
                      return (
                        <ListItem
                          disableGutters
                          key={sub.text}
                          style={{
                            paddingBottom: 0
                          }}
                        >
                          <MyLink to={`${to}/${sub.to}`}>
                            {sub.Icon ? <sub.Icon /> : null}
                            <Typography
                              variant="button"
                            >
                              {t(sub.text)}
                            </Typography>
                          </MyLink>
                        </ListItem>
                      );
                    })}
                  </List>
                </ListItem>
              );
            }

            return (
              <ListItem
                disableGutters
                key={text}
                style={{
                  paddingBottom: 0
                }}
              >
                <MyLink
                  to={to}
                >
                  {Icon ? <Icon /> : null}
                  <Typography
                    variant="button"
                  >
                    {t(text)}
                  </Typography>
                </MyLink>
              </ListItem>
            );
          })
        }
      </List>
    </Box>
  );
};

export default Navigation;
import { battery_0, battery_75, battery_50, battery_25, battery_100, battery_Error } from '../assets/battery';

const Battery_0 = () => {
  return <img style={{ width: '100%', maxWidth: '25px' }} alt='Batteria' src={battery_0} />
}
const Battery_25 = () => {
  return <img style={{ width: '100%', maxWidth: '25px' }} alt='Batteria' src={battery_25} />
}
const Battery_50 = () => {
  return <img style={{ width: '100%', maxWidth: '25px' }} alt='Batteria' src={battery_50} />
}
const Battery_75 = () => {
  return <img style={{ width: '100%', maxWidth: '25px' }} alt='Batteria' src={battery_75} />
}
const Battery_100 = () => {
  return <img style={{ width: '100%', maxWidth: '25px' }} alt='Batteria' src={battery_100} />
}
const Battery_Error = () => {
  return <img style={{ width: '100%', maxWidth: '25px' }} alt='Batteria' src={battery_Error} />
}

export {
  Battery_0,
  Battery_25,
  Battery_50,
  Battery_75,
  Battery_100,
  Battery_Error
};
import { alarmApi, dashboardApi, deviceApi, deviceTypeDetailsApi, deviceTypesApi, storeApi, userInfoApi, userStoreApi, counterApi, commandApi } from "api";
import deviceInfoApi from "api/deviceApi";
import { useSelector, useDispatch } from "react-redux";
import userManager from "../services/userManager";

import { RootState } from "../store/rootReducer";
import { UserInfo, updateUser, resetUser } from "../store/slices/user";

const useUserInfo = () => {
  const userInfo = useSelector<RootState, UserInfo>((state) => state.user);
  const dispatch = useDispatch();

  return {
    userInfo,
    update: (payload: Partial<UserInfo>) => dispatch(updateUser(payload)),
    logout: () => {
      userManager.logout();
      dispatch(resetUser(''));
      dispatch(userInfoApi.util.resetApiState());     
      dispatch(storeApi.util.resetApiState());
      dispatch(deviceInfoApi.util.resetApiState());
      dispatch(deviceTypeDetailsApi.util.resetApiState());
      dispatch(userStoreApi.util.resetApiState());
      dispatch(dashboardApi.util.resetApiState());
      dispatch(deviceTypesApi.util.resetApiState());
      dispatch(deviceApi.util.resetApiState());
      dispatch(alarmApi.util.resetApiState());
      dispatch(counterApi.util.resetApiState());
      dispatch(commandApi.util.resetApiState());
    },
  }
};

export default useUserInfo;
import { useIntl } from "react-intl";

interface IuseFormattedMessage {
  prefix?: string;
};

const useFormattedMessage = ({ prefix = '' }: IuseFormattedMessage = {}) => {
  const { formatMessage } = useIntl();

  return (key: string, values: Record<string, any> = {}, opts: any = {}) => formatMessage({ id: prefix.concat(key) },values, opts)
};

export default useFormattedMessage;
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";

const routeRoot = 'alarms/';

export interface IStore {
  id: number;
  uuid: string;
  name: string;
  address: string;
}

export interface IDevice {
  id: number;
  uuid: string;
  name: string;
  ipAddress: string;
  macAddress: string;
  latitude: string;
  longitude: string;
  notes: string;
  mqttTopic?: string;
  deviceType?: number;
}

export interface IReadings {
  id: number;
  idStore: number;
  dataName: string;
  dataValue: number;
  readingAt: Date;
}

export interface IAlarms {
  id: number;
  idDevice: number;
  dataField: string;
  dataValue: number;
  active: boolean;
  alarmDescr: string;
  alarmTreshold: number;
  createdOn: Date;
  updatedOn: Date;
  managed: boolean;
}

const alarmApi = createApi({
  tagTypes: ['Alarm'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'alarmReducer',
  endpoints: (build) => ({
    getLastReadings: build.query<IReadings[], number>({
      query: (idDevice) => routeRoot.concat(`readingLast/${idDevice}`),
      keepUnusedDataFor: 120,
      providesTags: ['Alarm']
    }),
    getLastReadingsTime: build.query<IReadings, number>({
      query: (idDevice) => routeRoot.concat(`readingLastTime/${idDevice}`),
      keepUnusedDataFor: 120,
      providesTags: ['Alarm']
    }),
    getStore: build.query<IStore, number>({
      query: (storeId) => routeRoot.concat(`store/${storeId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Alarm']
    }),
    getDevice: build.query<IDevice, number>({
      query: (deviceId) => routeRoot.concat(`device/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Alarm']
    }),
    getAlarm: build.query<IAlarms, number>({
      query: (deviceId) => routeRoot.concat(`alarm/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Alarm']
    }),
    getAllAlarmByDeviceId: build.query<IAlarms[], number>({
      query: (deviceId) => routeRoot.concat(`alarm/all/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Alarm']
    }),
    getAllActiveAlarmByDeviceId: build.query<IAlarms[], number>({
      query: (deviceId) => routeRoot.concat(`alarm/active/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Alarm']
    }),
    manageAlarm: build.mutation<any, { body: Partial<IAlarms>  }>({
      query: ({ body }) => ({
        url: routeRoot.concat(body.id?.toString() || ''),
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Alarm'],
    })
  }),
});

export default alarmApi;

export const {
  useGetLastReadingsQuery,
  useGetLastReadingsTimeQuery,
  useGetStoreQuery,
  useGetDeviceQuery,
  useGetAlarmQuery,
  useGetAllActiveAlarmByDeviceIdQuery,
  useGetAllAlarmByDeviceIdQuery,
  useManageAlarmMutation
} = alarmApi;
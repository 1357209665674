import { merge } from "ramda";
import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";

import { Langs } from "../../translations/constants";

export interface LanguageInfo {
  language: Langs;
}


const slice = createSlice<LanguageInfo, SliceCaseReducers<LanguageInfo>>({
  name: 'language',
  initialState: {
    language: Langs.EN,
  },
  reducers: {
    setLanguage: (state, action: PayloadAction<LanguageInfo>) => merge(state, { language: action.payload.language }),
  },
});

export default slice.reducer;

export const { setLanguage } = slice.actions;
import { CircularProgress } from '@material-ui/core';
import { Suspense } from 'react';
import { Routes, Route } from "react-router-dom";
import paths from "./routes/index";

function App() {
  return (
    <Suspense fallback={<p style={{ textAlign: "center" }}> <CircularProgress /> </p>}>
      <Routes>
        {paths.map((props) => {
          return <Route {...props} />;
        })}
      </Routes>
    </Suspense>
  );
}

export default App;

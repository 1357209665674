import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";
import { IDeviceTypeCommand } from "./deviceTypeCommandApi";

const routeRoot = 'Commands/';

export interface ICommand {
  id: number;
  idDevice: number;
  idDeviceTypeCommand: number;
  macAddress: string;
  commandValue: string;
  minutesDelay: number;
  createdOn: number;
  executed: boolean;
  executedAt: Date;
}

const commandApi = createApi({
  tagTypes: ['Command','DeviceTypeCommand','Dashboard'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'commandReducer',
  endpoints: (build) => ({
    addCommand: build.mutation<any, { body: Partial<ICommand> }>({
      query: ({ body }) => ({
        url: routeRoot,
        method: 'POST',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Command'],
    }),    
    getCommandsListByIdDevice: build.query<IDeviceTypeCommand[], number>({
      query: (id) => routeRoot.concat(`${id}`),
      keepUnusedDataFor: 120,
      providesTags: ['DeviceTypeCommand','Dashboard','Command']
    }),
  }),
});

export default commandApi;

export const {
  useAddCommandMutation,
  useGetCommandsListByIdDeviceQuery
} = commandApi;
import { useState } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Avatar,
  Drawer,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Menu, ChevronLeft } from "@material-ui/icons";

import { Link } from "react-router-dom";

import Navigation from "./Navigation";

import { useGetUserInfoQuery } from "../../api/userApi";
import { Logo } from "components";


interface LayoutProps {
  children: JSX.Element;
}

const Layout = ({ children }: LayoutProps) => {

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [ open, setOpen ] = useState(smallScreen);

  const { data } = useGetUserInfoQuery();

  return (<Container maxWidth={false} disableGutters>
      <Drawer
        open={open}
        variant={smallScreen ? 'temporary' : 'permanent'}
        onClose={() => setOpen(false)}
      >
        <Box minWidth={200} height="100%" pt={6} >
          <Navigation onClick={() => setOpen(false)} />
        </Box>
    </Drawer>
    <AppBar
      style={{ zIndex: 1400 }}
      position="sticky"
    >
      <Toolbar disableGutters >
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingX={2}
        >
          <Box>
            {smallScreen ? (
              <IconButton
                color="inherit"
                onClick={() => setOpen((s) => !s)}
                edge="start"
              >
                {open ? (
                  <ChevronLeft color="inherit" />
                ) : (
                  <Menu color="inherit" />
                )}
              </IconButton>
            ) : null}
          </Box>
        <Logo></Logo>
          <Box>
            <Link style={{ textDecoration: 'none' }}  to="/profile">
              <Avatar> {data?.username.slice(0, 2).toUpperCase()} </Avatar>
            </Link>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
    <Box
      pl={smallScreen ? 0 : '200px'}
      py={4}
    >
      {children}
    </Box>

  </Container>);
};

export default Layout;
class UserManager {
  localStorage: Storage;
  keys = {
    token: 'token',
    expires: 'exp',
  };
  constructor(localStorage: Storage) {
    this.localStorage = localStorage;
  }

  saveData = (token: string, expiry: number) => {
    this.localStorage.setItem(this.keys.token, token);
    this.localStorage.setItem(this.keys.expires, `${expiry}`);
  }

  getData = () => {
    return {
      token: this.localStorage.getItem(this.keys.token),
      expiresIn: this.localStorage.getItem(this.keys.expires),
    }
  }

  logout = () => {
    this.localStorage.removeItem(this.keys.expires);
    this.localStorage.removeItem(this.keys.token);
  }

};

const userManager = new UserManager(localStorage);

export default userManager;
import { FC } from "react";
import {
  styled,
  alpha,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { 
  Battery_0,
  Battery_25,
  Battery_50,
  Battery_75,
  Battery_100,
  Battery_Error
} from "./Battery";

import { Alarm } from "./Alarm";
import { Counter } from "./Counter";

interface ReadingCellProps {
  data: {
    SensorId: number;
    SensorName: string;
    DataValue?: string;
    DataUM?:string;
    DataIsBattery?: boolean;
    BatteryValue?: number;
    BatteryState?: number;
    AlarmActive?: boolean;
    CounterActive?: boolean;
  };
  onEdit?: () => void;
  onDelete?: () => void;
}

const ReadingSection = styled('div')(({ theme }) => {
  const border = `1px solid ${alpha(theme.palette.primary.main, .5)}`
  return {
    padding: theme.spacing(1),
    borderTop: border,
    "&:last-child": {
      borderBottom: border,
    }
  }
});


const ReadingCell: FC<ReadingCellProps> = ({
  data,
}) => {

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  console.log(data);

  return (
    <ReadingSection>
      <Box
        display="flex"
        flexDirection={smallScreen ? "row" : "row" }
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography
            variant="h5"
            style={{
              lineHeight: '28px',
            }}
          >
            {data.SensorName}&nbsp;
            {/* <Typography
            variant="caption"
            color="primary"
            style={{
              lineHeight: '28px',
            }}
          >
             ({data.sensorMacAddres})
          </Typography> */}
          </Typography>
        </Box>
       
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Box
            display="flex"
            flexDirection={smallScreen ? "row" : "row" }
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Typography
                variant="h5"
                className="role"
                color="primary"
                style={{
                  lineHeight: '28px',
                  textAlign: 'right'
                }}
              >
              {data.DataIsBattery ? 
              (<p style={{ textAlign: "right" }}> {data.BatteryValue} {data.DataUM} </p> ) : data.DataValue} 
                
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Typography
                variant="h5"
                className="role"
                color="primary"
                style={{
                  lineHeight: '28px',
                  width: '40px',
                  textAlign: 'left',
                  marginLeft: '10px'
                }}
              >
                 {data.DataIsBattery ? 
                    {
                      5: <Battery_100 />,
                      4: <Battery_75 />,
                      3: <Battery_50 />,
                      2: <Battery_25 />,
                      1: <Battery_0 />,
                      0: <Battery_Error />,
                    }[data.BatteryState ?? 0]
              : data.DataUM}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Typography
                variant="h5"
                className="role"
                color="primary"
                style={{
                  lineHeight: '28px',
                  width: '40px',
                  textAlign: 'right',
                  marginLeft: '10px'
                }}
              >
                 {data.AlarmActive ? 
                <Alarm/>
                : null}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Typography
                variant="h5"
                className="role"
                color="primary"
                style={{
                  lineHeight: '28px',
                  width: '40px',
                  textAlign: 'right',
                  marginLeft: '10px'
                }}
              >
                 {data.CounterActive ? 
                <Counter/>
                : null}
              </Typography>
            </Box>
          </Box>
        </Box>
       
      </Box>
    </ReadingSection>
  );

};

export default ReadingCell;